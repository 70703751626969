<template>
  <div class="gestao-grupos-acessos">
    <crud
      :title="'Gestão de grupos de acessos'"
      buttonAddItemText="Novo grupo"
      :dialog.sync="dialog"
      :formTitle="formTitle"
      :callBackModalCancelar="fechar"
      :callBackModalSalvar="salvar"
      :callBackModalInsert="resetFields"
      :tamanhoModalCrud="800"
      searchLabelText="Pesquisar na lista"
      :searchTextField.sync="searchTextField"
      :dialogDelete.sync="dialogDelete"
    >
      <template slot="data-table">
        <v-data-table
          :loading="loadingControl.dataTable"
          :headers="computedHeaders"
          :items="listaGrupoAcesso"
          :search="searchTextField"
          items-per-page-text="Linhas por página"
          no-results-text="Nenhum registro encontrado"
          mobile-breakpoint="0"
          class="elevation-5"
        >
          <template v-slot:[`item.acesso_master`]="{ item }">
            <v-switch
              :loading="loadingControl.master[`${item.id}`]"
              input-value="false"
              :true-value="'S'"
              :false-value="'N'"
              v-model="item.acesso_master"
              :label="item.acesso_master == 'S' ? 'Sim' : 'Não'"
              @change="editarSwitch(item, 'master')"
            ></v-switch>
          </template>
          <template v-slot:[`item.libera_acesso`]="{ item }">
            <v-switch
              :loading="loadingControl.libera[`${item.id}`]"
              input-value="false"
              :true-value="'S'"
              :false-value="'N'"
              v-model="item.libera_acesso"
              :label="item.libera_acesso == 'S' ? 'Sim' : 'Não'"
              @change="editarSwitch(item, 'libera')"
            ></v-switch>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-switch
              :loading="loadingControl.status[`${item.id}`]"
              input-value="false"
              :true-value="'A'"
              :false-value="'I'"
              v-model="item.status"
              :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
              @change="editarSwitch(item, 'status')"
            ></v-switch>
          </template>
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="toggleModalLateral(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                    <span>Visualizar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="editar(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                    <span>Editar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="remover(item, true)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                    <span>Apagar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | dateTimeFormatBr() }}</span>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
          </template>
          <template v-slot:no-data>
            <label>Nenhum registro disponível</label>
          </template>
        </v-data-table>
      </template>
      <template slot="contentModalCrud">
        <v-container fluid>
          <v-row wrap>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="Nome"
                data-vv-as="nome"
                dense
                persistent-hint
                v-model="objetoEdicao.nome"
                :hint="errors.first('grupo.nome')"
                :error="errors.collect('grupo.nome').length ? true : false"
                v-validate="'required'"
                data-vv-scope="grupo"
                data-vv-validate-on="change"
                id="nome"
                name="nome"
                trim
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="listaEmpresas"
                label="Empresas"
                data-vv-as="empresas"
                dense
                item-text="nome"
                item-value="id"
                outlined
                v-model="objetoEdicao.id_empresa"
                :hint="errors.first('grupo.id_empresa')"
                :error="
                  errors.collect('grupo.id_empresa').length ? true : false
                "
                v-validate="'required'"
                data-vv-scope="grupo"
                data-vv-validate-on="change"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="4">
                  <v-switch
                    label="Acesso master"
                    v-model="objetoEdicao.acesso_master"
                    :true-value="'S'"
                    :false-value="'N'"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    label="Libera Acessos"
                    v-model="objetoEdicao.libera_acesso"
                    :true-value="'S'"
                    :false-value="'N'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :items="listaMenus"
                label="Menus"
                data-vv-as="menu"
                dense
                item-text="nome"
                item-value="id"
                outlined
                v-model="objetoEdicao.menus"
                :hint="errors.first('grupo.menus')"
                :error="errors.collect('grupo.menus').length ? true : false"
                v-validate="'required'"
                data-vv-scope="grupo"
                data-vv-validate-on="change"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.nome }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{
                      objetoEdicao.menus && objetoEdicao.menus.length - 1
                    }}
                    outros)</span
                  >
                </template>

                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleSelect('menus', $event)">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          objetoEdicao.menus && objetoEdicao.menus > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ iconSelect("menus", "menu") }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          objetoEdicao.menus.length != listaMenus.length
                            ? "Selecionar todos"
                            : "Desmarcar todos"
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
      <template slot="modalDelete/titleModal">Excluir o registro?</template>
      <template slot="modalDelete/contentModal">
        <p>
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
        </p>
        <p>Você realmente deseja excluir esse registro?</p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn color="error" @click="remover()">Excluir</v-btn>
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
    </crud>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="image" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
    <modalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="
        headersModal.filter((header) => {
          return headersModal.value != 'visualizar';
        })
      "
      :item="modalLateral.item"
    >
      <template v-slot:[`item.menus`]="{ item }">
        <div v-if="item.menus && item.menus.length > 0">
          <div v-for="menu in item.menus" :key="menu">
            <span class="my-1">{{
              listaMenus.find((el) => el.id == menu) == null
                ? "Não tem menu." + menu
                : listaMenus.find((el) => el.id == menu).nome
            }}</span>
            <br />
          </div>
        </div>
        <div v-else>
          <span>Não tem menu.</span>
        </div>
      </template>
      <template v-slot:[`item.libera_acesso`]="{ item }">
        <span>{{ item.libera_acesso == "S" ? "Sim" : "Não" }} </span>
      </template>
      <template v-slot:[`item.acesso_master`]="{ item }">
        <span>{{ item.acesso_master == "S" ? "Sim" : "Não" }} </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}</span
        >
      </template>
    </modalLateral>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
export default {
  name: "Grupo-Acesso",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  metaInfo() {
    return {
      title: "Gestão grupo de acesso",
    };
  },
  data() {
    return {
      image: logo,
      searchTextField: "",
      item: [],
      dialogDelete: false,
      dialog: false,
      loadingControl: {
        dataTable: false,
        master: [],
        libera: [],
        status: [],
      },
      loadingGestaoMenu: false,
      headers: [
        { text: "Nome", value: "nome", sortable: true },
        { text: "Acesso master", value: "acesso_master", sortable: false },
        { text: "Libera acessos", value: "libera_acesso", sortable: false },
        { text: "Status", value: "status", sortable: false, hide: "smAndDown" },
        { text: "Cadastro", value: "created_at", hide: "smAndDown" },
        { text: "Atualização", value: "updated_at", hide: "smAndDown" },
        { text: "Ações", value: "acao", sortable: false, align: "center" },
      ],
      headersModal: [
        {
          text: "Uuid",
          value: "uuid",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Acesso master",
          value: "acesso_master",
        },
        {
          text: "Libera acesso",
          value: "libera_acesso",
        },
        {
          text: "Menus",
          value: "menus",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Data de criação",
          value: "created_at",
        },
        {
          text: "Última alteração",
          value: "updated_at",
        },
      ],
      pesquisaDataTable: "",
      objetoSalvar: [],
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      buttonAction: false,
      objetoEdicao: {
        nome: "",
        empresa_id: "",
        acesso_master: "N",
        libera_acesso: "N",
        status: "A",
        menus: [],
      },
      indexEdicao: -1,
      edicao: false,
    };
  },
  methods: {
    editar(item) {
      this.edicao = true;
      this.indexEdicao = this.listaGrupoAcesso.findIndex(
        (el) => el.id == item.id
      );
      this.objetoEdicao = Object.assign({}, item);
      this.dialog = true;
    },
    resetFields() {},
    fechar() {
      this.dialog = false;
      this.objetoEdicao = {
        nome: "",
        empresa_id: "",
        acesso_master: "N",
        libera_acesso: "N",
        status: "A",
        menus: [],
      };
    },
    salvar() {
      this.$validator.validate("grupo.*").then((result) => {
        if (result) {
          this.loadingControl.dataTable = true;

          let url =
            this.edicao === false
              ? "/grupo-acesso"
              : "/grupo-acesso/" + this.objetoEdicao.uuid;
          let method = this.edicao === false ? "POST" : "PUT";
          let text =
            this.edicao === false ? "Grupo cadastrado." : "Grupo atualizado.";
          let erro =
            this.edicao === false
              ? "Erro ao salvar grupo acesso."
              : "Erro ao atualizar grupo acesso.";
          let item = this.objetoEdicao;
          item._method = method;
          item.url = url;

          this.$store
            .dispatch("configuracoes/salvarGrupoAcesso", { item })
            .then((response) => {
              if (response) {
                console.log("estou no front", response, this.indexEdicao);
                this.indexEdicao === -1
                  ? this.listaGrupoAcesso.push(response)
                  : Object.assign(
                      this.listaGrupoAcesso[this.indexEdicao],
                      item
                    );

                this.$store.dispatch("snackBarModule/openSnackBar", {
                  color: "success",
                  timeout: 3000,
                  text: text,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "error",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              this.edicao = false;
              this.indexEdicao = -1;
              this.loadingControl.dataTable = false;
            });
        } else {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Preencha todo formulário antes de salvar!",
          });
        }

        this.fechar();
      });
    },
    editarSwitch(item, tipo) {
      this.indexEdicao = this.listaGrupoAcesso.indexOf(item);
      this.edicao = true;
      switch (tipo) {
        case "master":
          this.loadingControl.master[`${item.id}`] = true;
          this.objetoEdicao = item;
          this.objetoEdicao.acesso_master =
            this.objetoEdicao.acesso_master === "S" ? "S" : "N";
          this.salvar();
          this.loadingControl.master[`${item.id}`] = false;
          break;
        case "libera":
          this.loadingControl.libera[`${item.id}`] = true;
          this.objetoEdicao = item;
          this.objetoEdicao.libera_acesso =
            this.objetoEdicao.libera_acesso === "S" ? "S" : "N";
          this.salvar();
          this.loadingControl.libera[`${item.id}`] = false;
          break;
        case "status":
          this.loadingControl.status[`${item.id}`] = true;
          this.objetoEdicao = item;
          this.objetoEdicao.status =
            this.objetoEdicao.status === "A" ? "A" : "I";
          this.salvar();
          this.loadingControl.status[`${item.id}`] = false;
          break;
      }
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingControl.dataTable = true;
        const indexItem = this.listaGrupoAcesso.indexOf(this.item);

        this.$store
          .dispatch("configuracoes/removerGrupoAcesso", this.item.uuid)
          .then((response) => {
            if (response.data) {
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });

              console.log(indexItem);
              this.listaGrupoAcesso.splice(indexItem, 1);
              this.item = {};
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingControl.dataTable = false;
          });

        this.dialogDelete = false;
      }
    },
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    iconSelect(tipo, item) {
      if (this.listaMenus && this.objetoEdicao.menus) {
        if (this.listaMenus.length == this.objetoEdicao.menus.length)
          return "mdi-close-box";
        if (this.objetoEdicao.menus.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggleSelect(tipo) {
      this.listaMenus.length == this.objetoEdicao.menus.length
        ? (this.objetoEdicao.menus = [])
        : (this.objetoEdicao.menus = this.listaMenus.map((t) => t.id));
    },
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    listaGrupoAcesso: {
      get() {
        return this.$store.getters["configuracoes/getGruposAcesso"];
      },
      set(value) {
        return value;
      },
    },
    listaEmpresas: {
      get() {
        return this.$store.getters["empresas/getEmpresasAtivas"];
      },
    },
    listaMenus: {
      get() {
        return this.$store.getters["configuracoes/getMenusPorColunas"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["configuracoes/getLoadingGrupoAcesso"];
      },
    },
    formTitle() {
      return this.edicao === false
        ? "Cadastrar grupo de acesso"
        : "Editar grupo de acesso";
    },
  },
  created() {
    const columns = ["id", "nome"];

    this.$store.dispatch("configuracoes/carregarGruposAcesso");
    this.$store.dispatch("empresas/buscarEmpresasAtivas");
    this.$store.dispatch("configuracoes/carregarMenusPorColunas", columns);
  },
};
</script>

<style></style>
